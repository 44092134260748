import React from "react";
import Home from "../views/Home";
import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";
import Blog from "../components/blog/Blog";
import About from "../components/about/About";
import BlogPost from "../components/blog/BlogPost";
import News from "../components/news/News";
import NewDetail from "../components/news/NewDetail";
import SocialNetwork from "../components/portfolio/SocialNetwork";
import Courses from "../components/courses/Courses";
import Services from "../components/services/Services";

const AllRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:slug" element={<NewDetail />} />
        <Route path="/social" element={<SocialNetwork />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRouter;
