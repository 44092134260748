import React, { useState } from "react";
import Modal from "react-modal";
import client from "../../services/client";
import Contact from "../Contact";
import { InfinitySpin } from  'react-loader-spinner';
import Constants from "../utils/Constants";

Modal.setAppElement("#root");

class CallToActionTwo extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      footerEntry: null
    };
  }

  toggleModalOne = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  componentDidMount = () => {
    client.getEntry(Constants.FooterEntryId)
    .then(response => {                
      const footerData = {
        backgroundUrl: response.fields.backgroundImage.fields.file.url,
        title: response.fields.title,
        buttonText: response.fields.buttonText,
        buttonLink: response.fields.buttonLink || '#'
      };

      this.setState({ footerEntry: footerData })      
    })
    .catch(console.error);
  }

  render() {
    const { isOpen, footerEntry } = this.state;

    if (!footerEntry) {
      return (
        <div className="loading">
            <InfinitySpin width='200' color="#9400D3" />
        </div>
      );  
    }

    return (
    <div className="shane_tm_section" id="contact">
      <div
        className="shane_tm_talk bg_image_props"
        style={{
          backgroundImage: `url('${footerEntry.backgroundUrl}')`,
        }}
      >
        <div className="shape">
          <img className="svg" src="/img/svg/paper.svg" alt="partners brand" />
        </div>
        {/* End shape */}

        <div className="background" id="talk">
          <a className="player"></a>
          <div className="overlay"></div>
        </div>
        {/* End background */}

        <div className="talk_inner">
          <div className="text">
            <h3>{ footerEntry.title }</h3>
          </div>

          <div className="button">
          <a href={footerEntry.buttonLink} className="white-fill-bg" target="_blank" rel="noopener noreferrer">
          { footerEntry.buttonText }
          </a>
          </div>
        </div>
        {/* End talk_inner */}
      </div>
      {/* Start Modal */}
      <Modal
        isOpen={isOpen}
        onRequestClose={this.toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="shane_tm_mobalbox_contact">
          <button className="close-modal" onClick={this.toggleModalOne}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              {/* Start modal content */}
              <div className="title">
                <h3>Ingresa tus datos para descargar el ebook</h3>
              </div>
              {/* End title */}

              <div className="wrapper">
                <div className="left">
                  <div className="fields">
                    <Contact />
                  </div>
                </div>
                {/* End left */}
                <div className="right">
                  <div className="map_wrap">
                    <img alt="Gratitud" src="img/callto-action/gratitude.jpeg" style={{ borderRadius: '1px solid #ccc' }} />
                  </div>
                </div>
                {/* End right */}
              </div>
              {/* End wrapper */}                            
            </div>
          </div>
          {/* End box inner */}
        </div>
        {/* End modalbox news */}
      </Modal>
      {/* End modal */}
    </div>
    )
  }
}

export default CallToActionTwo;
