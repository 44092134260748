import React from "react";
import client from "../../services/client";
import HeaderAlt from "../header/HeaderAlt";
import Footer from "../footer/Footer";
import AboutSummary from "./AboutSummary";
import Constants from "../utils/Constants";
import { InfinitySpin } from  'react-loader-spinner';
import ReactGA from 'react-ga4';

// Add Analytics tracking
ReactGA.send({ hitType: "pageview", page: "/about", title: "About" });

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeEntry: null,
    };
  }

  componentDidMount() {
    client.getEntry(Constants.AboutEntryId).then((response) => {
      this.setState({ homeEntry: response });
    });
  }

  render() {
    const { homeEntry } = this.state;

    if (!homeEntry) {
      return (
        <div className="loading">
            <InfinitySpin width='200' color="#9400D3" />
        </div>
      );      
    }

    return (
      <div className="home-two">
        <HeaderAlt />
        {/* End Header Section */}

        <AboutSummary />
        {/* End About Section */}

        <Footer />
      </div>
    );
  }
};

export default About;
