import React from 'react';
import client from "../../services/client";
import Constants from "../utils/Constants";
import HeaderAlt from '../header/HeaderAlt';
import Footer from '../footer/Footer';
import { Dna, InfinitySpin } from  'react-loader-spinner';
import ReactGA from 'react-ga4';

// Add Analytics tracking
ReactGA.send({ hitType: "pageview", page: "/servies", title: "Mis Sesiones" });

class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          servicesEntry: null,
          isSummary: props.isSummary || false,
        };
    }
    
    componentDidMount() {
        client.getEntry(Constants.ServicesEntryId)
        .then((response) => {
          this.setState({ servicesEntry: response });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    
    render() {
        const { servicesEntry, isSummary } = this.state;

        if (!servicesEntry) {
            return (
                <div className="loading">
                    <InfinitySpin width='200' color="#9400D3" />
                </div>
            );      
        }

        return (
            <div className="home-two">
              
              { !isSummary && <HeaderAlt /> }
              {/* End Header Section */}
            
              { /* Body */}
              <div className="shane_tm_section" id="services">
                <div className="shane_tm_about">
                  
                  { isSummary && 
                    <div className="container">
                      <div className="about_inner">
                        <div className="left">
                            <div
                            className="shane_tm_title"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                            >
                            <span>Mis Sesiones</span>
                            <h3>{ servicesEntry.fields.title }</h3>
                            </div>
                        </div>
                        {/* End left */}
                        {/* End right */}
                     </div>
                    </div>
                  }

                  { /* Intro */ }
                  <div className="container">
                    <div className="about_inner">                    
                      
                      <div className="left">
                          <img src={`https:${servicesEntry.fields.imageIntro.fields.file.url}`} 
                          alt="Intro"
                          />
                      </div>
                      
                      <div className="right">
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{ __html: servicesEntry.fields.bodyIntro }}
                        >
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Intro */}

                  <br />

                  { /* Atention */ }
                  <div className="container">
                    <div className="about_inner">        
                      <div className="left">
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{ __html: servicesEntry.fields.bodyAtention }}
                        >
                        </div>                        
                      </div>
                      
                      <div className="right">
                        <img src={`https:${servicesEntry.fields.imageAtention.fields.file.url}`} 
                          alt="Intro"
                          />                        
                      </div>
                    </div>
                 </div>
                {/* End Atention */}

                <br />

                { /* Schedule */ }
                  <div className="container">
                    <div className="about_inner">                    
                      
                      <div className="left">
                          <img src={`https:${servicesEntry.fields.imageSchedule.fields.file.url}`} 
                          alt="Intro"
                          />
                      </div>
                      
                      <div className="right">
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{ __html: servicesEntry.fields.bodySchedule }}
                        >
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Schedule */}

                </div>
              </div>

              { !isSummary && <Footer /> }
            </div>
          );
    }
};

export default Services;
